
import useMightyMouse from 'react-hook-mighty-mouse';
import React, { useState, useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdWork } from "react-icons/md";
import { FaReact } from "react-icons/fa";
import { AiFillHtml5 } from "react-icons/ai";
import { GiPaintBrush } from "react-icons/gi";
import { DiPython } from "react-icons/di";
import { SiLinux } from "react-icons/si";
import { AiOutlineCode } from "react-icons/ai";
import { GrWordpress } from "react-icons/gr";
import Fade from 'react-reveal/Fade';
import { useForm } from "react-hook-form";
import './App.css';
// import './modal-video.scss';
import Formular from './Components/Formular'
import { hotjar } from 'react-hotjar'
// import '../node_modules/react-modal-video/scss/modal-video.scss';
// import '../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'





function App() {

  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    hotjar.initialize(3137682, 6)
  }, [])

  

  const [inputValue, setInputValue] = useState("");
  const onChangeHandler = event => {
    setInputValue(event.target.value);
    
 };


  const [base64, setBase64] = useState("");
 
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
 
  const onLoad = (fileString) => {
    setBase64(fileString);
    console.log(base64);
  };
 
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(file);
      onLoad(reader.result);
      console.log(base64);
    };
  };

  const handleSubmit = (e) =>{
    e.preventDefault()

    fetch("https://l8066d5ibf.execute-api.eu-central-1.amazonaws.com/sendEmail",
    {
      mode: "no-cors",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        senderName: "razvanburloiu@gmail.com",
        senderEmail: "razvanburloiu@gmail.com",
        // message: "HELLO WORLD THIS IS FROM REACT APP P.S. Lebron the GOAT.",
        message: inputValue,
        base64Data: base64,
        date: new Date(),
        fileName: "TEST_FILE_NAME",
      }),
    }
    
    )
  }
  
  const {
    selectedElement: {
      position: { angle: angleLeftEye },
    },
  } = useMightyMouse(true, 'left-eye', { x: 20, y: 20 });
  const {
    selectedElement: {
      position: { angle: angleRightEye },
    },
  } = useMightyMouse(true, 'right-eye', { x: 20, y: 20 });

  const rotateLeftEye = `rotate(${-angleLeftEye}deg)`;
  const rotateRightEye = `rotate(${-angleRightEye}deg)`;

  return (
    <div>

      {/* <video autoPlay loop muted>
        <source
          src="/fire-background.mp4"
          type="video/mp4"
        />
      </video> */}


{/* <div><video src="/fire-background2.mp4" autoPlay loop muted/> */}
<div>
 

  
      
      <div className="hero">
        
        <div className="flex-container">
  <video src="/files/blue_background.mp4" autoPlay loop playsInline muted/>
          <div className="item"> 
          <p className='hello'>Hello, I'm</p> 
          <br/> 
          <p className="nume">Răzvan</p>  

          <p> FrontEnd/FullStack Developer</p>
          </div>
          <div className="item"> <img className="logo" src="/logo.png" alt="skull logo image" />
          
          <div className="eyes-follow">
      
      <div className="eyes">
        <div id="left-eye" className="eye" style={{ transform: rotateLeftEye }}>
          
          <div className="pupil" />
          
        </div>
        <div id="right-eye" className="eye" style={{ transform: rotateRightEye }}>
          <div className="pupil" />
        </div>
      </div>
    </div>
          
          
          </div>
        </div>
      </div>

      {/* CLOSING HERO SECTION */}
      <div className="proiecte-personale-big">

        <h2 style={{color:"white", paddingTop: "35px"}}>Personal Projects</h2>
        <ModalVideo
						channel="youtube"
						autoplay
						isOpen={isOpen}
						videoId="m-5ShSXxUsg"
						onClose={() => setOpen(false)}
					/>
        <ModalVideo
						channel="youtube"
						autoplay
						isOpen={isOpen1}
						videoId="4obCaVABnGI"
						onClose={() => setOpen1(false)}
					/>
					
					

    <div className="grid-container">

    <ul>

<li className="proiecte"><div className="flip-card">
<div className="flip-card-inner">
<div className="flip-card-front">
  <h3>Ecommerce</h3>
  <h3>Stripe Payments Integrated </h3>
</div>
<div className="flip-card-back">
  <h3>Technologies</h3> 
  <p>React JS</p>
  <p>Stripe</p>
  <p>Node JS</p>
  <p>REST API</p>
  <br/>
  <a target="_blank" href="https://stripeproject.vercel.app/">View Demo</a>
  
  {/* <p className="text-modal" onClick={() => setOpen(true)}>
       View Video
      
    </p> */}

</div>
</div>
</div>
</li>
</ul>

    <ul>

      <li className="proiecte"><div className="flip-card">
    <div className="flip-card-inner">
      <div className="flip-card-front">
        <h3>Food Delivery Chrome Extension </h3>
      </div>
      <div className="flip-card-back">
        <h3>Technologies</h3> 
        <p>React JS</p>
        <p>REST API</p>
        <br/>
        {/* <a href="https://www.w3schools.com">View Demo</a> */}
        
        <p className="text-modal" onClick={() => setOpen(true)}>
						 View Video
            
					</p>

    </div>
    </div>
  </div>
  </li>
    </ul>


    <ul>

      <li className="proiecte"><div className="flip-card">
    <div className="flip-card-inner">
      <div className="flip-card-front">
        <h3>Product Presentation Website</h3>
      </div>
      <div className="flip-card-back">
        <h3>Technologies</h3> 
        {/* <p>React JS</p> */}
        <p>HTML</p>
        <p>CSS</p>
        <a href="https://galaxy-website-orcin.vercel.app/" target="_blank">View Demo</a>
        
    </div>
    </div>
  </div></li>
    </ul>
    <ul>

      <li className="proiecte"><div className="flip-card">
    <div className="flip-card-inner">
      <div className="flip-card-front">
        <h3>Law Firm Website </h3>
      </div>
      <div className="flip-card-back">
        <h3>Technologies</h3> 
        <p>HTML</p>
        <p>CSS</p>
        <a href="https://law-website-eight.vercel.app/" target="_blank" >View Demo</a>
    </div>
    </div>
  </div></li>
    </ul>
    <ul>

      <li className="proiecte"><div className="flip-card">
    <div className="flip-card-inner">
      <div className="flip-card-front">
        <h3>Fast Food API </h3>
      </div>
      <div className="flip-card-back">
        <h3>Technologies</h3> 
        <p>ExpressJS</p>
        <p>MongoDB</p>
        <p>REST API</p>
        <p>try /kfc & /burgerking</p>
        <a href="https://vercelfastfoodapi.vercel.app/fastfood/" target="_blank">View Demo</a>
    </div>
    </div>
  </div></li>
    </ul>

    <ul>

      <li className="proiecte"><div className="flip-card">
    <div className="flip-card-inner">
      <div className="flip-card-front">
        <h3>Python Holiday Scrapper</h3>
      </div>
      <div className="flip-card-back">
        <h3>Technologies</h3> 
        <p>Python</p>
        <p>Selenium</p>
        {/* <a href="https://www.w3schools.com" target="_blank">Visit W3Schools</a> */}
        <p className="text-modal" onClick={() => setOpen1(true)}>
						 View Video
            
					</p>
    </div>
    </div>
  </div></li>
    </ul>

    </div>

</div>

{/* TEHNOLOGII */}

      <div className='tehnologii-section'>
        <h2 style={{color:"white", paddingTop: "6%"}}>Technologies</h2>
        
        <div className="tehnologii-grid">
        <Fade bottom>
          <div className='tehnologii-item'> 
          
            <FaReact  className="reactIcon" color='white' size={100} style={{display: "flex", justifyItems: "center", alignItems:"center"}}/> 
            
            <h4>ReactJS</h4>
          
          </div>
          
          
          <div className='tehnologii-item'>
            
            <AiFillHtml5  className="html5Icon" color='white' size={100} style={{display: "flex", justifyItems: "center", alignItems:"center"}}/> 
            <h4>HTML</h4>
          </div>
          
          <div className='tehnologii-item'>
            <GiPaintBrush  className="reactIcon" color='white' size={100} style={{display: "flex", justifyItems: "center", alignItems:"center"}}/> 

            <h4>CSS</h4>
          </div>
          <div className='tehnologii-item'>
            <AiOutlineCode className="expressIcon" color='white' size={100} style={{display: "flex", justifyItems: "center", alignItems:"center"}}/> 

            <h4>ExpressJS</h4>
          </div>
          </Fade>
          </div>
          
          <div className="tehnologii-grid2">

          <Fade bottom> 
          <div className='tehnologii-item'>
            <GrWordpress className="reactIcon" color='white' size={100} style={{display: "flex", justifyItems: "center", alignItems:"center"}}/> 

            <h4>Wordpress</h4>

          </div>

          <div className='tehnologii-item'>
            <DiPython className="pythonIcon" color='white' size={100} style={{display: "flex", justifyItems: "center", alignItems:"center"}}/> 

            <h4>Python</h4>

          </div>
          <div className='tehnologii-item'>
            <SiLinux className="pythonIcon" color='white' size={100} style={{display: "flex", justifyItems: "center", alignItems:"center"}}/> 

            <h4>Ubuntu</h4>

          </div>
          </Fade>
          </div>

        
          
          
      </div>

 {/* EXPERIENTA */}
      <div className='experianta-section'>
        <h2 style={{color:"white"}}>Experience</h2>
        <div className='div-cv'> <h3  className='cv-download'>  <a href = '/files/burloiu_razvan_cv.pdf' target = "_blank" className='cv-download'>Download CV</a></h3>  </div>

      
 <VerticalTimeline lineColor='#007cff'>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#003266', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #003266' }}
    date="Febr 2022 - Present"
    iconStyle={{ background: '#003266', color: '#fff', 
    boxShadow:
    "0 0 0 4px #007cff, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05)"}}
    icon={<MdWork />}
    
  >
    <h3 className="vertical-timeline-element-title">Junior Automation QA</h3>
    <h4 className="vertical-timeline-element-subtitle">Bucharest, RO</h4>
    
    <ul>
      <p>•	Develop and implement automated tests <br/> <a className='cv-technologies'>Techonolgies: JavaScript, Cypress</a> </p>
      <p>•	Manual Stress test server and gather data for future project<br/>  </p>
    
    </ul>
  </VerticalTimelineElement>
  <VerticalTimelineElement
  contentStyle={{ background: '#003266', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #003266' }}
    className="vertical-timeline-element--work"
    date="Nov 2019 - Febr 2022"
    iconStyle={{ background: '#003266', color: '#fff', 
    boxShadow:
    "0 0 0 4px #007cff, inset 0 2px 0 rgb(0 50 102), 0 3px 0 4px rgba(0,0,0,.05)"}}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Junior FullStack Developer</h3>
    <h4 className="vertical-timeline-element-subtitle">Bucharest, RO</h4>
    {/* <p>
      Creative Direction, User Experience, Visual Design, SEO, Online Marketing
    </p> */}

    <ul>
        <p>•	Worked on a new mobile application helping local sellers deliver their products to their customers <br/> <a className='cv-technologies'>Techonolgies: React Native, GIT, REST</a> </p>
        <p>•	Developed and maintained an automated tool responsible for checking the correct functionalities of multiple forms on the client website <br/> <a className='cv-technologies'>Techonolgies: Python, Selenium</a> </p>
        <p>•	Built the back-end API and assured the connection to a NoSql database for new software that makes it easy to create documents. <br/> <a className='cv-technologies'>Techonolgies: ExpressJS, MongoDB, GIT </a> </p>
        <p>•	Developed a script that synchronizes data from MySQL database and remote platform.  <br/> <a className='cv-technologies'>Techonolgies: Python, MySQL, REST, GIT</a> </p>
        <p>•	Create a new WordPress plugin that generates pdf forms based on input fields.  <br/> <a className='cv-technologies'>Techonolgies: PHP, WordPress</a> </p>

    </ul>

  </VerticalTimelineElement>



</VerticalTimeline>
</div>

{/* CONTACT */}
<div className='contact-section'>
        <h2 style={{color:"white"}}>Contact</h2>
       
        {/* UPPLOAD ATTACHMENT FORMULAR MINI CARE MERGE  */}
        {/* <form>
          <p> Nume </p>
        <input
   type="text"
   name="name"
   onChange={onChangeHandler}
   value={inputValue}
/>


          <input type="file" accept='application/pdf' onChange={onChange}/>
        </form> */}
        {/* <button onClick={handleSubmit}> SEND </button> */}
        {/* {base64 ? <button onClick={handleSubmit}> SEND </button> : <button onClick={handleSubmit} disabled> SEND </button>} */}


      <Formular/>
</div>

</div>



    </div>
  );
}




export default App;
