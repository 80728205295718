import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import './App.css';
import { AiFillGithub } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";


  

export default function Formular() {
    // const { register, handleSubmit } = useForm();
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [base64, setBase64] = useState("");

    const onChange = (e) => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
      };

      const onLoad = (fileString) => {
        setBase64(fileString);
        console.log(base64);
      };
     
      const getBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          console.log(file);
          onLoad(reader.result);
          console.log(base64);
        };
      }


    const onSubmit = (e) => {
        
        // console.log(e)
        // e.preventDefault()
    
        fetch("https://l8066d5ibf.execute-api.eu-central-1.amazonaws.com/sendEmail",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            senderName: "razvanburloiu@gmail.com",
            senderEmail: "razvanburloiu@gmail.com",
            // message: "HELLO WORLD THIS IS FROM REACT APP P.S. Lebron the GOAT.",
            message: "<div>" + "<p> Nume " + e.firstName + " " + e.lastName+ "</p> <br/> <p> Email: " + e.mail + "</p> <br/> <p>"+ e.textarea+" </div>",
            base64Data: base64,
            date: new Date(),
            fileName: "TEST_FILE_NAME",
          }),
        })

    };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="formular">


        <div className="grid-contact-container">
            <div className="gridContactItem">

                <p className="fieldName">Full name*</p>
      
      </div>


            <div className="gridContactItem">
            <input {...register("firstName", { required: true })} placeholder="First Name"/>
      {errors.firstName?.type === 'required' && <div> <br/><p style={{float: "none", margin: "0", color: "red"}}>First name is required</p></div>}

      <input {...register("lastName", { required: true })}  placeholder="Last Name"/>
      {errors.lastName && <div> <br/><p style={{float: "none", margin: "0", color: "red"}}>Last name is required</p></div>}

                
      </div>

      <div className="gridContactItem">   
        <p className="fieldName">Email*</p>
      
        <div> <br/><p style={{ color: "red"}}>{errors.mail?.message}</p> </div>
        </div> 


        <div className="gridContactItem">   
        <input type="email" {...register("mail", { required: "Email Address is required" })  } placeholder="Email" />
        </div>


            {/* <div className="gridContactItem">


            <p>Salary Range (€)*</p>
    
            </div>

            <div className="gridContactItem">

            

            
            <select {...register("salary", {
        required: "Required"
    })}>

        <option value=""> Please select </option>
        <option value="0-500"> 0-500 </option>
        <option value="500-800"> 500-800 </option>
        <option value="800-1000"> 800-1000 </option>
        <option value="1000-1500"> 1000-1500 </option>
        <option value="1500+"> 1500+ </option>
        
    </select>
        {errors.salary && <p>Salary range is required</p>}


            </div> */}


            <div className="gridContactItem">   
            <p className="fieldName">More Details</p>
            
        
        
        </div>  

        <div className="gridContactItem">   
                        
        <textarea {...register("textarea")} id="textareaID" rows="4" cols="50" placeholder="Information about your company, your contact details, work from home, the project, etc"></textarea>
        
        </div> 

        <div className="gridContactItem">   
        <p className="fieldName">Upload file (pdf only)</p>
        </div> 

        <div className="gridContactItem">   
        <input type="file" accept='application/pdf' onChange={onChange}/>
        

        </div>




        <div className="gridContactItem">   
        <input type="submit" />
        </div> 

        <div className="gridContactItem">   
        <a href="https://www.linkedin.com/in/razvan-burloiu-47750b13b/" target="_blank"> <AiFillLinkedin color="white" size={50}/> </a>
        <a href="https://github.com/konjic" target="_blank"> <AiFillGithub color="white" size={50}/> </a>
        </div> 
        

        </div>        

    </form>
  );
}